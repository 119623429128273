.contact-container {
  max-width: 600px;
  margin: 50px auto;
  margin-top: 200px;
  padding: 40px;
  background-color: #0D4F4E; /* Background matching register page */
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(13, 79, 78, 0.5); /* Darker shadow for consistency */
}

.contact-container h2 {
  text-align: center;
  color: #efe6dd; /* Matching text color */
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 28px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjusted spacing for consistency */
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* Label styling to match register page */
.form-group label {
  font-weight: bold;
  color: #efe6dd;
  margin-bottom: 5px;
}

/* Input and Textarea Styling */
.form-group input,
.form-group textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd; /* Border style for consistency */
  border-radius: 5px;
  background-color: #faf3e0; /* Light background */
  color: #6E6E6E; /* Text color matching inputs on register page */
  box-sizing: border-box;
}

/* Input focus styling */
.form-group input:focus,
.form-group textarea:focus {
  background-color: #faf3e0;
  border-color: #2a574a;
  outline: none;
}

.form-group textarea {
  resize: vertical;
  height: 120px;
}

/* Submit Button Styling */
.submit-btn {
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #faf3e0;
  color: #0D4F4E;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.submit-btn:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

/* Success Message Styling */
.success-message {
  text-align: center;
  font-size: 25px;
  color: #efe6dd;
}
