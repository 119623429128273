/* Conteneur FAQ avec animation de lévitation */
.faq-container {
  max-width: 800px;
  margin: 50px auto;
  margin-top: 200px;
  padding: 40px; /* Ajusté pour correspondre au padding de la page Register */
  background-color: #0D4F4E; /* Couleur de fond identique à la page Register */
  border-radius: 10px; /* Bordure arrondie similaire à Register */
  box-shadow: 0 0 15px rgba(13, 79, 78, 0.5); /* Ombre plus sombre pour cohérence */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
}

/* Effet de lévitation au survol */
.faq-container:hover {
  transform: translateY(-10px); /* Lévitation vers le haut */
  box-shadow: 0 20px 30px rgba(13, 79, 78, 0.3); /* Ombre dynamique similaire à Register */
}

/* Animation du titre */
.faq-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #efe6dd; /* Couleur du texte identique */
  font-weight: bold;
  font-size: 28px;
  position: relative;
  transition: color 0.3s ease;
}

/* Liste de la FAQ */
.faq-list {
  margin-top: 20px;
}

/* Style des éléments de la FAQ */
.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #efe6dd;
  transition: transform 0.3s ease;
}

/* Animation des questions */
.faq-question {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 15px;
  background-color: #0D4F4E; /* Même fond que Register */
  color: #efe6dd; /* Couleur identique aux labels de Register */
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative;
}

/* Animation et changement de couleur au survol des questions */
.faq-question:hover {
  background-color: #0D4F4E; /* Conserve le fond mais pourrait être ajusté si besoin */
  transform: translateX(10px); /* Légère translation pour effet dynamique */
}

/* Style des réponses */
.faq-answer {
  font-size: 16px;
  padding: 15px;
  display: none;
  color: #A8A8A8; /* Couleur secondaire pour le texte */
  font-weight: bold;
  background-color: #faf3e0; /* Couleur de fond similaire aux champs d'input de Register */
  border-radius: 5px;
  margin-top: 5px;
  transition: max-height 0.5s ease;
  overflow: hidden;
}

/* Afficher la réponse avec une animation fluide */
.faq-answer.show {
  display: block;
  max-height: 500px;
}

/* Lorsque la FAQ est active */
.active .faq-question {
  background-color: #0D4F4E;
  transform: translateX(0); /* Remettre à zéro la translation */
}
