.pricing-container {
  max-width: 100%;
  text-align: center;
  justify-content: center;
}

.pricing-header {
  color: #0D4F4E;
  padding: 40px;
  font-size: 55px;
  text-align: center;
  justify-content: center;
}

.pricing-subtext {
  color: #555555;
  margin-bottom: 40px;
  font-size: 1.2rem;
}

.pricing-section {
  display: flex; /* Active Flexbox */
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
  gap: 20px; /* Espace entre les conteneurs */
  justify-content: center; /* Centre horizontalement */
  align-items: stretch; /* Étire tous les conteneurs pour qu'ils aient la même hauteur */
}


/* Conteneur Flex pour aligner les images VIP et les cartes en colonnes */
.vip-images-container, .pricing-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.vip-image {
  width: 330px; /* Largeur fixe pour chaque image VIP */
  height: auto;
  animation: fadeInUp 0.5s ease-in-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vip-image:hover {
  transform: translateY(-10px);
}

/* Style des cartes de prix pour alignement avec les images */
.pricing-cards {
  display: flex;
  gap: 20px;
  align-items: stretch;
  width: 100%;
}

.pricing-cards .card .price {
  font-size: 25px;
  color: #6E6E6E;
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 5px solid #0D4F4E !important;  /* Bordure uniquement à gauche */
  border-right: 5px solid #0D4F4E !important;  
  padding: 30px;
  background-color: #faf3e0; /* Couleur de fond douce */
  animation: fadeInUp 0.5s ease-in-out;
  box-shadow: none; /* Supprime toute ombre */
  border: #faf3e0;

}


/* Alignement et hauteur fixes pour chaque élément */
.card h3 {
  color: #0D4F4E;
  font-size: 1.8rem;
  min-height: 40px;
  text-align: center;
  margin-bottom: 0 !important;
}

.card .price {
  font-size: 1.5rem;
  min-height: 40px;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.start-btn {
  display: block;
  background-color: #0D4F4E;
  border: none;
  color: #efe6dd;
  padding: 12px 0;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Transition appliquée ici */
  margin-top: auto;

}

.start-btn:hover {
  background-color: #0D4F4E;
  transform: translateY(-5px);
}

.features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
  flex-grow: 1;
}

.features li {
  color: #6E6E6E;
  padding: 8px 0;
  font-size: 20px;
}


/* Animation d'apparition des cartes */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 1310px) {
  .pricing-section {
    flex-wrap: nowrap; /* Les conteneurs restent sur une seule ligne */
    justify-content: center; /* Centre les conteneurs */
  }

  .vip-card-container {
    max-width: 330px; /* Taille fixe pour chaque conteneur */
  }
}




.billing-toggle {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 30px;
}

.billing-toggle button {
  padding: 10px 20px;
  border: 2px solid #0D4F4E !important;
  background-color: #faf3e0;
  color: #0D4F4E;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
}

.billing-toggle button.active {
  background-color: #0D4F4E;
  color: #efe6dd;
}

.billing-toggle button:hover {
  background-color: #0D4F4E;
  color: #efe6dd;
}

/* Style pour le badge "25% OFF" */
.discount-badge {
  position: absolute;
  top: -30px;
  left: -10px;
  background-color: #0D4F4E;
  color: #efe6dd;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  transform: rotate(-10deg);
  white-space: nowrap;
}

/* Style pour la note de facturation annuelle */
.billing-note {
  font-size: 12px;
  color: #6e6e6e;
  display: block;
  margin-top: 5px;
}

.vip-card-container {
  flex: 1; /* Étend chaque conteneur pour qu'il prenne la même hauteur que les autres */
  display: flex;
  flex-direction: column; /* Empile l'image et la carte */
  align-items: center; /* Centre les enfants horizontalement */
  gap: 20px;
  min-width: 300px; /* Largeur minimale pour les petits écrans */
  max-width: 330px; /* Largeur maximale sur grand écran */
}


.vip-card-container .vip-image {
  width: 100%; /* L'image occupe toute la largeur disponible */
  max-width: 250px; /* Limite la largeur maximale */
  height: auto; /* Garde les proportions de l'image */
}

.vip-card-container .card {
  width: 100%; /* La carte occupe toute la largeur */
  flex: 1; /* Étend la carte pour qu'elle remplisse l'espace vertical disponible */
  text-align: center;
  display: flex; /* Active Flexbox pour le contenu interne */
  flex-direction: column;
  justify-content: space-between; /* Distribution verticale uniforme */
}
