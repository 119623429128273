/* PrivateNav.css */

.private-navbar {
  background-color: #0D4F4E; /* Couleur de fond de la navbar publique */
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Ombre douce comme la navbar publique */
}

.private-navbar ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.private-navbar li {
  display: inline;
}

.private-navbar a {
  text-decoration: none;
  color: #faf3e0; /* Couleur de texte de la navbar publique */
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
  font-weight: bold;
}

.private-navbar a:hover {
  color: #efe6dd; /* Changement de couleur au survol */
  transform: translateY(-2px); /* Effet de légère élévation au survol */
}

.private-navbar a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #efe6dd;
  transition: width 0.3s ease-in-out;
}

.private-navbar a:hover::after {
  width: 65%; /* Effet de soulignement au survol */
}

.private-navbar a:focus {
  outline: none;
  border-bottom: 2px solid #efe6dd; /* Style de focus pour l’accessibilité */
}

@media (max-width: 768px) {
  .private-navbar {
      flex-direction: column;
      align-items: flex-start;
  }

  .private-navbar ul {
      flex-direction: column;
      gap: 1rem;
  }

  .private-navbar a {
      font-size: 1rem;
  }
}
