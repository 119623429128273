body {
  background-color: #faf3e0;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #faf3e0;
}

.register-box {
  background-color: #0D4F4E;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(13, 79, 78, 0.5);
  width: 400px;
  text-align: center;
}

.register-box h2 {
  margin-bottom: 20px;
  color: #efe6dd;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

/* Style des labels */
.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #efe6dd !important; /* Couleur pour les labels */
}

/* Style des champs de saisie */
.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  color: #6E6E6E; /* Couleur du texte à l'intérieur du champ */
  background-color: #faf3e0; /* Optionnel : couleur de fond */
}
.password-group {
  position: relative; /* Positionne le conteneur en relatif pour l'alignement du bouton "Show" */
}

/* Style pour le lien "Show/Hide" de la visibilité du mot de passe */
.password-group {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px; /* Ajuste l'espacement par rapport au bord droit */
  top: 50%;
  transform: translateY(-50%);
  color: #0D4F4E;
  margin-top: 15px;
  font-size: 13px; /* Ajustez pour qu'elle corresponde à la taille du texte de l'input */
  line-height: 1; /* Assure que le texte dans le bouton est centré */
  padding: 0; /* Enlevez les marges et les paddings pour un meilleur centrage */

}


/* Style pour les conditions d'utilisation */
.terms {
  margin-top: 10px;
  font-size: 12px;
}

.terms a {
  color: #007bff;
  text-decoration: none;
}

/* Style du lien vers la page de connexion */
.login-link {
  margin-top: 20px;
  font-size: 14px;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover,
.terms a:hover {
  text-decoration: underline;
}

/* Style pour le bouton d'enregistrement */
.register-btn {
  background-color: #faf3e0; /* Dégradé avec les couleurs de TradyList */
  color: #0D4F4E;
  padding: 0.8rem 1.5rem; /* Augmentation du padding pour plus de confort */
  border-radius: 50px; /* Bouton bien arrondi */
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Ombre douce pour un effet de profondeur */
  transition: all 0.3s ease; /* Transition fluide pour l'effet au survol */
}

.register-btn:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Ombre plus marquée au survol */
  transform: translateY(-3px); /* Légère élévation au survol */
}
