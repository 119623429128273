.beta-signup-form {
    background-color: #faf3e0; /* Couleur de fond douce */
    border: 1px solid #faf3e0;   /* Bordure légère */
    border-radius: 8px;       /* Coins arrondis */
    padding: 20px;           /* Espacement interne */
    max-width: 500px;        /* Largeur maximale */
    margin: 20px auto;       /* Centrer le composant */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre subtile */
    text-align: center;      /* Centrer le texte */
  }
  
  .beta-signup-form h2 {
    font-size: 1.5rem;
    color: #0D4F4E;            /* Couleur du titre */
    margin-bottom: 16px;    /* Espacement sous le titre */
  }
  
  .beta-signup-form label {
    display: block;
    font-weight: bold;      /* Texte en gras */
    margin-bottom: 8px;     /* Espacement sous le label */
    color: #0D4F4E;            /* Couleur du label */
  }
  
  .beta-signup-form input[type="email"] {
    width: 100%;            /* Occupe toute la largeur */
    padding: 10px;          /* Espacement interne */
    border: 1px solid #ccc; /* Bordure discrète */
    border-radius: 4px;     /* Coins légèrement arrondis */
    margin-bottom: 12px;    /* Espacement sous le champ */
    font-size: 1rem;        /* Taille de texte */
  }
  
  .beta-signup-form input[type="email"]:focus {
    border-color: #0D4F4E;  /* Couleur d'accentuation au focus */
    outline: none;          /* Retirer le contour */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Ombre au focus */
  }
  
  .beta-signup-form button {
    background-color: #0D4F4E; /* Couleur bleu primaire */
    color: #efe6dd;              /* Texte blanc */
    padding: 10px 20px;       /* Espacement interne */
    border: none;             /* Pas de bordure */
    border-radius: 4px;       /* Coins arrondis */
    font-size: 1rem;          /* Taille de texte */
    cursor: pointer;          /* Curseur pointeur */
    transition: background-color 0.3s ease; /* Animation de hover */
  }
  
  
  .beta-signup-form .message {
    margin-top: 16px;         /* Espacement au-dessus du message */
    font-size: 1rem;          /* Taille de texte */
    color: #0D4F4E;           /* Couleur verte pour succès */
  }
  
  .beta-signup-form .message.error {
    color: #dc3545;           /* Couleur rouge pour erreurs */
  }
  