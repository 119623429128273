/* Style global pour le footer */
.footer {
    background-color: #0D4F4E;
    color: #efe6dd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 45vh;
    width: 100%;
    box-sizing: border-box;
}

/* Conteneur principal du contenu du footer */
.footer-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
}

/* Section individuelle du footer */
.footer-section {
    flex: 1;
    text-align: center;
    margin-bottom: 5px;
}

.footer-section h3 {
    margin-bottom: 10px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li a {
    color: #efe6dd;
    text-decoration: none;
    transition: text-decoration 0.3s ease; /* Transition douce pour le survol */
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

/* Bas du footer */
.footer-bottom {
    text-align: center;
    margin-top: 60px;
}

@media (max-width: 1100px) {
    
    .footer-section{
    margin-top: 20px;
    }

}