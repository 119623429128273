/* ---- Navbar ---- */
.navbar {
  background-color: #0D4F4E;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Répartition entre logo, liens et switcher */
  padding: 10px 20px;
  height: 100px;
  position: relative; /* Permet de positionner des éléments comme le switcher */
  z-index: 1000; /* S'assurer que la navbar reste au-dessus */
}

/* ---- Conteneur principal ---- */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Répartition équilibrée */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* ---- Logo ---- */
.navbar-logo-brand img {
  max-width: 90px;
  max-height: 90px;
  object-fit: contain;
}

/* ---- Liens de navigation ---- */
.navbar-links {
  display: flex; /* Alignement en ligne des liens */
  justify-content: center; /* Centre les liens horizontalement */
  align-items: center; /* Centre les liens verticalement */
  list-style: none; /* Supprime les puces */
  padding: 0;
  margin: 0;
  gap: 60px; /* Espacement uniforme entre les liens */
  flex: 1; /* Permet d'occuper l'espace disponible */
}

.navbar-links li {
  list-style: none; /* Supprime les puces */
}

.nav-button {
  text-decoration: none;
  color: #faf3e0;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: #efe6dd;
}

.nav-button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #efe6dd;
  transition: width 0.3s ease-in-out;
}

.nav-button:hover::after {
  width: 50%;
}

/* ---- Language Switcher ---- */
.language-switcher {
  display: flex;
  gap: 10px; /* Espacement entre les boutons */
}

.language-switcher {
  display: flex;
  gap: 10px; /* Ajoute un espacement entre les boutons */
}

.language-switcher button {
  background: none;
  border: none;
  color: #efe6dd;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.language-switcher button:hover {
  text-decoration: underline;
}


/* ---- Bouton Burger (Menu Mobile) ---- */
.menu-toggle {
  display: none; /* Caché par défaut */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  z-index: 1000;
  padding: 0;
}

.menu-toggle.open {
  transform: rotate(90deg);
}

.menu-toggle div {
  width: 100%;
  height: 3px;
  background-color: #faf3e0;
  border-radius: 2px;
}

/* Fermeture du menu mobile */
.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: #faf3e0;
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

/* ---- Responsivité Mobile ---- */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 15px;
    height: auto; /* Laisse la hauteur s'adapter au contenu */
    flex-wrap: wrap; /* Permet au contenu de passer à la ligne */
  }

  .navbar-logo-brand img {
    max-width: 100px; /* Réduit encore la taille du logo sur mobile */
    height: auto;
  }

  .menu-toggle {
    display: flex; /* Affiche le menu burger */
  }

  .navbar-links {
    display: none; /* Caché par défaut sur mobile */
    flex-direction: column; /* Empile les liens verticalement */
    align-items: center;
    width: 100%; /* Les liens occupent toute la largeur */
    background-color: #0D4F4E;
    position: absolute;
    top: 100%; /* En dessous de la navbar */
    left: 0;
    z-index: 999;
    padding: 20px 0;
  }

  .navbar-links.show {
    display: flex; /* Affiche les liens lorsqu'on ouvre le menu */
  }

  .nav-button {
    font-size: 1.2rem; /* Taille adaptée pour le mobile */
    width: 100%; /* Les boutons occupent toute la largeur */
    text-align: center;
  }

  .language-switcher {
    position: static; /* Désactive la position absolue */
    margin-top: 20px; /* Ajoute un espacement sous les liens */
    justify-content: center; /* Centre les langues */
    width: 100%;
  }
  

  .close-menu {
    display: block; /* Affiche le bouton pour fermer le menu */
  }
}

/* ---- Désactivation des contours pour l'accessibilité ---- */
button, a, .nav-button, .nav-button:focus, .nav-button:active {
  outline: none;
  box-shadow: none;
  border: none;
}
