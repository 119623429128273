/* Reset de base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about-us {
  text-align: center;
  padding: 40px;
}

.about-us-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
  margin-top: 100px;
}

.about-us-images-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permet de passer les images sur plusieurs lignes si nécessaire */
  max-width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.about-us-image {
  max-width: 500px;
  width: 100%; /* Pour que l'image s'adapte à la largeur de son conteneur */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-us-description {
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

/* Responsiveness */
@media (max-width: 1024px) {
  .about-us-title {
    font-size: 2rem;
    margin-top: 80px;
  }

  .about-us-description {
    font-size: 1rem;
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .about-us-title {
    font-size: 1.8rem;
    margin-top: 60px;
  }

  .about-us-description {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .about-us-title {
    font-size: 1.5rem;
    margin-top: 50px;
  }

  .about-us-images-container {
    gap: 10px; /* Réduction de l’espace entre les images */
  }

  .about-us-description {
    font-size: 0.9rem;
  }
}
