/* Conteneur centré pour le bouton */
.free-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px; /* Espacement autour du bouton */
}

/* Style de base pour le bouton "Start for Free" */
.free-button {
  background-color: #0D4F4E; /* Couleur de fond */
  color: #efe6dd; /* Couleur du texte */
  padding: 10px 20px; /* Espacement interne */
  font-size: 20px; /* Taille du texte */
  border: none; /* Supprime les bordures */
  border-radius: 20px; /* Coins arrondis */
  text-align: center; /* Centre le texte */
  cursor: pointer; /* Change le curseur */
  display: flex; /* Permet d'empiler les éléments */
  flex-direction: column; /* Empile les éléments verticalement */
  align-items: center; /* Centre les éléments horizontalement */
  justify-content: center; /* Centre les éléments verticalement */
  margin: 250px 0; /* Marge pour centrer verticalement */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation fluide */
}

/* Effet de survol pour le bouton */
.free-button:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Ombre au survol */
  transform: translateY(-6px); /* Légère élévation au survol */
}

/* Texte secondaire dans le bouton */
.free-button .free-text {
  font-size: 20px; /* Taille du texte */
  margin-top: 2px; /* Espacement entre les lignes */
  color: #efe6dd; /* Même couleur que le texte principal */
}
