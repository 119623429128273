/* InventoryFilter.css */

.inventory-container {
  background-color: #faf3e0;
  padding: 20px;
  border-radius: 8px;
  margin-top: 50px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; /* Centre le conteneur */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.inventory-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0D4F4E;
  text-align: center;
}

.filter-section {
  display: flex;
  gap: 20px;
  justify-content: center; /* Centre les catégories */
  flex-wrap: wrap;
}

.filter-category {
  background-color: #0D4F4E;
  padding: 20px;
  border-radius: 8px;
  width: 500px; /* Largeur accrue pour uniformité */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.filter-title {
  font-size: 1.2rem;
  color: #efe6dd;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  justify-items: center; /* Centre les items dans la grille */
}

/* Styles pour les items avec images dans la section Marketplaces */
.filter-item {
  width: 160px;
  height: 60px;
  background-color: #efe6dd;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-item:hover {
  background-color: #faf3e0;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.filter-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.filter-item:hover .filter-image {
  transform: scale(1.1);
}

/* Styles pour les items sans images dans la section Tags */
.tag-item {
  width: 60px;
  height: 60px;
  background-color: #efe6dd;
  border-radius: 8px;
  color: #0D4F4E;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tag-item:hover {
  background-color: #faf3e0;
  transform: translateY(-3px);
}

/* Barre de recherche */
.search-bar {
  background-color: #0D4F4E;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  color: #faf3e0;
  background-color: transparent;
}

.search-input::placeholder {
  color: #faf3e0;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
