body {
  background-color: #faf3e0;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #faf3e0;
}

.login-box {
  background-color: #0D4F4E;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(13, 79, 78, 0.5);
  width: 400px;
  text-align: center;
}

.login-box h2 {
  margin-bottom: 20px;
  color: #efe6dd;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

/* Style for labels */
.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #efe6dd !important;
}

/* Style for input fields */
.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  color: #6E6E6E;
  background-color: #faf3e0;
}

/* Style for the toggle password visibility */
.toggle-password {
  color: #0D4F4E;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Style for the "Forgot Password" link */
.forgot-password {
  margin-top: 10px;
  font-size: 12px;
}

.forgot-password a {
  color: #efe6dd;
  text-decoration: none;
}

/* Style for the "Register" link */
.register-link {
  margin-top: 20px;
  font-size: 14px;
  color: #efe6dd;
}

.register-link a {
  color: #efe6dd;
  text-decoration: none;
}

.register-link a:hover,
.forgot-password a:hover {
  text-decoration: underline;
}

/* Style for the login button */
.login-btn {
  background-color: #faf3e0;
  color: #0D4F4E;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.login-btn:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}
