
html, body {
    width: 100%;
    height: 100%;
    background-color: #faf3e0;
    overflow-x: hidden; /* Évite le débordement horizontal */
    font-family: Arial, sans-serif;
}

/* Conteneur principal pleine largeur */
.full-width-container {
    width: 100%;
    background-color: #faf3e0;
}

/* Titre principal */
.whattl-container {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000;
    background-color: #faf3e0;
}

/* Conteneur limité et centré */
.section-container {
    width: 100%;
    background-color: #faf3e0;
    align-items: center;
    text-align: center;
}

/* Structure de la ligne pour aligner l'image et le texte */
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 100%;
    gap: 20px; /* Espacement entre les colonnes */
    margin-right: 0; /* Annule la marge négative */
    margin-left: 0;  /* Annule également celle de gauche si nécessaire */
}

/* Styles de chaque colonne */
.col {
    flex: 1;
    padding: 20px;
}

/* Colonne de texte */
.text-content {
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Style pour les titres de section */
h1.section-title {
    font-weight: 700;
    color: #6E6E6E;
    margin-bottom: 60px;
    text-align: center;
    font-size: 40px;
}

/* Style pour les titres de section */
h3.section-title {
    font-weight: 700;
    color: #6E6E6E;
    margin-bottom: 60px;
    text-align: center;
    font-size: 30px;
}


/* Style pour les paragraphes de section */
.section-paragraph {
    font-size: 25px;
    line-height: 1.6;
    color: #A8A8A8;
    margin-bottom: 8px;
    text-align: center;
    font-weight: bold;
}

/* Colonne d'image */
.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

/* Style des images */
.feature-img {
    width: 100%;
    max-width: 1080px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s ease, opacity 0.4s ease, box-shadow 0.4s ease;
}

/* Effet de survol sur les images */
.feature-img:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
}

/* Animation pour l'apparition en cascade des images */
.feature-img.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
    animation: imageAppear 1.5s ease-out forwards;
}

/* Animation d'apparition */
@keyframes imageAppear {
    0% {
        opacity: 0;
        transform: translateY(40px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

/* Inverse l'ordre des colonnes pour les sections alternées */
.row-reverse {
    flex-direction: row-reverse;
}

/* Responsive - Tablette */
@media (max-width: 1024px) {
    .row {
        flex-direction: column; /* Passe en colonne pour les petits écrans */
        align-items: center;
    }

    .whattl-container {
        font-size: 40px;
    }
    .section-title {
        font-size: 28px;
    }
    .section-paragraph {
        font-size: 22px;
    }
    .feature-img {
        max-width: 100%;
    }
}

/* Responsive - Smartphone et petites tablettes */
@media (max-width: 768px) {
    .row {
        flex-direction: column-reverse; /* Place le texte au-dessus de l'image */
        align-items: center;
    }
    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .text-content {
        padding-bottom: 10px;
        text-align: center;
    }
    .section-container {
        padding: 0 20px;
    }
    .whattl-container {
        font-size: 32px;
        padding: 10px;
    }
    .section-title {
        font-size: 24px;
    }
    .section-paragraph {
        font-size: 18px;
    }
    .feature-img {
        max-width: 100vw;
        border-radius: 6px;
    }
}
