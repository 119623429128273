.ebaylogo{
    width: 100px;
    height: 100%;
}

.amazonlogo{
    width: 120px;
    height: 100%;
    margin-left : 3%;
}
.leboncoinlogo {
    width: 120px;
    height: 100%;
    margin-left : 3%;
}

.vintedlogo {
    width: 100px;
    height: 100%;
    margin-left : 3%;
}

.facebooklogo {
    width: 50px;
    height: 100%;
    margin-left : 3%;
}
.voggtlogo {
    width: 110px;
    height: 100%;
    margin-left : 3%;
}
.whatnotlogo {
    width: 70px;
    height: 100%;
    margin-left : 3%;
}

.Partnerlogo-container {
    margin-bottom: 10vw; /* Marge adaptée à l'écran */
    max-width: 100%;
    padding: 0 20px;
    display: flex; /* Utilisez flexbox pour aligner les logos horizontalement */
    flex-wrap: wrap; /* Permet aux logos de passer à la ligne sur petits écrans */
    justify-content: center; /* Centrer les logos dans le conteneur */
    align-items: center; /* Centrer les logos verticalement dans le conteneur */
    box-sizing: border-box;
}

.Partnerlogo-container img {
    max-width: 100px; /* Limitez la taille maximale des logos */
    height: auto; /* Assurez-vous que les images gardent leurs proportions */
    margin: 10px; /* Ajoutez un peu d'espace autour des images */
}

@media only screen and (max-width: 768px) {
    .Partnerlogo-container img {
        max-width: 80px; /* Réduisez la taille des images sur les petits écrans */
        margin: 5px; /* Ajustez l'espacement sur petits écrans */
    }
}

@media only screen and (max-width: 480px) {
    .Partnerlogo-container img {
        max-width: 60px; /* Taille encore plus petite pour les très petits écrans */
        margin: 3px; /* Moins d'espace entre les images */
    }
}
