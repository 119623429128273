/* Container for the entire section */
.product-container {
  width: 80%;
  margin: 0 auto;
  padding: 2rem 0;
}

/* Header section with "Vos articles" and the button */
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.add-button {
  background-color: #ccc;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: black;
}

/* Grid layout for the products */
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

/* Styling for individual product cards */
.product-card {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Modal overlay to cover the page */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: #fff;
  padding: 2rem;
  width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
}

/* Form inputs */
.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Modal buttons */
.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.save-button {
  background-color: #28a745;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
/* Image previews in the modal */
.image-previews {
  display: flex;
  gap: 10px;
  margin-top: 1rem;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
}

