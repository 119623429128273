/* Style global pour html et body */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #faf3e0;
    overflow-x: hidden; /* Évite le débordement horizontal */
    box-sizing: border-box; /* Uniformise le modèle de boîte */
}

/* Applique box-sizing à tous les éléments */
*, *::before, *::after {
    box-sizing: inherit;
}

/* Styles pour les titres principaux et secondaires */
.h1-title h1 {
    font-weight: 400;
    color: #6E6E6E;
    background-color: #faf3e0;
    margin: 0;
    text-align: center; /* Centre le titre */
    font-size: 40px;
}

.h2-title h2 {
    font-weight: 300;
    color: #6E6E6E;
    margin: 10px 0; /* Évite l'espacement excessif */
    text-align: center;
    padding-bottom: 200px;
    font-size: 30px;
}

/* Container pour la vidéo d'introduction */
.video-intro-container {
    width: 100%;
    max-width: 95%;
    height: auto;
    margin: 0 auto; /* Centre le conteneur horizontalement */
    display: flex;
    align-items: center; /* Centre les éléments verticalement */
    justify-content: center; /* Centre les éléments horizontalement */
}

/* Style pour les images avec classe custom-img-size */
.custom-img-size {
    max-width: 100%; /* Empêche les images de déborder de leur conteneur */
    height: auto; /* Conserve le ratio d'aspect */
    object-fit: contain; /* Garde l'image dans les limites sans déformation */
}

/* Effet de surlignement avec un marqueur */
.marker-highlight {
    position: relative;
    display: inline-block;
    background: #0D4F4E 50%;
    color: #faf3e0;
    padding: 0 5px;

}

