.features-container {
  max-width: 1100px;
  margin: 50px auto;
  margin-top: 150px;
  padding: 40px;
  background-color: #0D4F4E; /* Fond sombre pour correspondre à Register */
  border-radius: 10px; /* Bordure arrondie similaire */
  box-shadow: 0 0 15px rgba(13, 79, 78, 0.5); /* Ombre sombre pour cohérence */
}

/* Titre principal */
.features-container h2 {
  text-align: center;
  color: #efe6dd; /* Couleur de texte similaire à Register */
  margin-bottom: 22px;
  font-weight: 600;
  font-size: 1.8rem;
}

.feature-title {
  text-align: center;
}

.features-container .main-title {
  color: #efe6dd; /* Utilise la même couleur que les titres de Register */
}

.features-container .intro-paragraph {
  font-size: 20px;
  font-weight: bold;
  color: #efe6dd; /* Couleur cohérente */
}

/* Description des fonctionnalités */
.feature-description {
  text-align: center;
  font-weight: bold;
  color: #A8A8A8; /* Gris pour les descriptions */
}

.features-container p {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1rem;
  color: #A8A8A8; /* Gris doux pour le texte */
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Disposition en grille */
.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

/* Style de chaque fonctionnalité */
.feature-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #faf3e0; /* Fond clair pour contraste */
  padding: 20px;
  border-radius: 8px;
  border-left: 4px solid #0D4F4E; /* Bordure fine sombre */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Ombre douce */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.4s ease-out forwards;
}

/* Titre de chaque fonctionnalité */
.feature-item h3 {
  margin-bottom: 10px;
  color: #0D4F4E; /* Texte sombre */
  font-weight: 500;
  font-size: 1.4rem;
  min-height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.feature-item p {
  color: #A8A8A8; /* Gris doux pour le texte */
  font-size: 1rem;
  line-height: 1.6;
}

/* Animation subtile d'apparition */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Survol élégant avec ombre */
.feature-item:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}
